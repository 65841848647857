import { create } from "zustand";

type SmsNotificationsStore = {
  smsList: Set<string>;
  show: (smsId: string) => void;
  close: (smsId: string) => void;
  closeAll: () => void;
};

const useSmsNotificationsStore = create<SmsNotificationsStore>((set, get) => ({
  smsList: new Set(),
  show(smsId: string) {
    const { smsList } = get();
    const newSmsList = new Set(smsList);
    newSmsList.add(smsId);
    console.info("New SMS List", newSmsList);
    set({
      smsList: newSmsList,
    });
  },
  close(smsId: string) {
    const { smsList } = get();
    const newSmsList = new Set(smsList);
    newSmsList.delete(smsId);
    set({
      smsList: newSmsList,
    });
  },
  closeAll() {
    const newSmsList = new Set<string>();
    set({
      smsList: newSmsList,
    });
  },
}));

export default useSmsNotificationsStore;
