"use client";
import { HARDCODED_QUOTES_FOR_HOMEPAGE } from "@/components/FakeSmsNotificationsPortal";
import useSmsNotificationsStore from "@/libs/zustand/sms-notifications";
import { useCallback, useEffect, useState } from "react";

type UseSmsQueuerProps = {
  messagesToShow: number;
};

const useSmsQueuer = ({ messagesToShow }: UseSmsQueuerProps) => {
  const [id, setId] = useState(0);
  const [count, setCount] = useState(0);

  const getId = useCallback(() => {
    const nextId = id + 1 >= HARDCODED_QUOTES_FOR_HOMEPAGE.length ? 0 : id + 1;
    setId(nextId);
    return id;
  }, [id, setId]);
  const { show } = useSmsNotificationsStore();

  useEffect(() => {
    setTimeout(() => {
      setCount(count + 1);
      show(HARDCODED_QUOTES_FOR_HOMEPAGE[getId()].id);
    }, 1000);
  }, []);

  useEffect(() => {
    const randomInterval = Math.floor(Math.random() * 5000) + 3000;
    const interval = setInterval(() => {
      setCount(count + 1);
      show(HARDCODED_QUOTES_FOR_HOMEPAGE[getId()].id);
    }, randomInterval);
    if (count >= messagesToShow) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [getId, count, setCount]);
};

export default useSmsQueuer;
