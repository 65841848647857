"use client";
import React from "react";
import useSmsNotificationsStore from "@/libs/zustand/sms-notifications";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import Image from "next/image";
import logo from "@/app/icon.png";
import config from "@/config";
import useSmsQueuer from "@/hooks/useSmsQueuer";

export const HARDCODED_QUOTES_FOR_HOMEPAGE: {
  id: string;
  quote: string;
  author: string;
}[] = (
  [
    {
      quote:
        "I've failed over and over again in my life and that is why I succeed.",
      author: "Michael Jordan",
    },
    {
      quote:
        "Great things happen to those who don't stop believing, trying, learning, and being grateful.",
      author: "Roy T. Bennett",
    },
    {
      quote: "All progress takes place outside the comfort zone.",
      author: "Michael John Bobak",
    },
    {
      quote:
        "Success is the sum of small efforts, repeated day in and day out.",
      author: "Robert Collier",
    },
    {
      quote:
        "The only person you are destined to become is the person you decide to be.",
      author: "Ralph Waldo Emerson",
    },
    {
      quote:
        "Mastery is great, but even that is not enough. You have to be able to change course without a bead of sweat, or remorse.",
      author: "Tom Peters",
    },
    {
      quote:
        "Optimism is a happiness magnet. If you stay positive good things and good people will be drawn to you.",
      author: "Mary Lou Retton",
    },
    {
      quote:
        "In every day, there are 1,440 minutes. That means we have 1,440 daily opportunities to make a positive impact.",
      author: "Les Brown",
    },
    {
      quote:
        "He who knows when he can fight and when he cannot, will be victorious.",
      author: "Sun Tzu",
    },
    {
      quote:
        "Success is not final, failure is not fatal: It is the courage to continue that counts.",
      author: "Winston Churchill",
    },
    {
      quote: "The only way to do great work is to love what you do.",
      author: "Steve Jobs",
    },
    {
      quote: "Victory has a hundred fathers and defeat is an orphan.",
      author: "John F. Kennedy",
    },
    {
      quote: "Find out who you are and do it on purpose.",
      author: "Dolly Parton",
    },
    {
      quote: "Those who don't believe in magic will never find it.",
      author: "Roald Dahl, The Minpins",
    },
    {
      quote: "The hardest lift of all is lifting your butt off the couch.",
      author: "Rosalyn Schiller",
    },
    {
      quote: "A goal without a plan is just a wish.",
      author: "Antoine de Saint-Exupéry",
    },
    {
      quote:
        "A little more persistence, a little more effort, and what seemed hopeless failure may turn to glorious success.",
      author: "Elbert Hubbard",
    },
    {
      quote:
        "The only thing standing between you and your goal is the story you keep telling yourself as to why you can't achieve it.",
      author: "Jordan Belfort",
    },
    {
      quote:
        "If you spend too much time thinking about a thing, you'll never get it done.",
      author: "Bruce Lee",
    },
  ] as { quote: string; author: string }[]
)
  .map((value, id) => ({ ...value, id: id.toString() }))
  .sort(() => Math.random() - 0.5);
const FakeSmsNotificationsPortal = () => {
  useSmsQueuer({ messagesToShow: 10 });

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  return (
    <>
      <div className="fixed bottom-[10px] sm:top-[100px] right-5 flex flex-col-reverse sm:flex-col items-end gap-7">
        {HARDCODED_QUOTES_FOR_HOMEPAGE.map(({ id, quote, author }) => (
          <FakeSmsNotifications key={id} uniqueId={id}>
            <>
              <div className="shrink-0">
                <Image
                  src={logo}
                  alt={`${config.appName} logo`}
                  placeholder="blur"
                  priority={true}
                  className="w-12 h-12 object-contain rounded-[12px]"
                  height={100}
                  width={100}
                />
              </div>
              <div className="w-full">
                <div>{quote}</div>
                <div className="font-bold">{author}</div>
              </div>
            </>
          </FakeSmsNotifications>
        ))}
      </div>
    </>
  );
};

const FakeSmsNotifications: React.FC<{
  uniqueId: string;
  children: React.ReactNode;
  config?: { duration: number; role: string };
}> = ({ uniqueId, config, children }) => {
  const duration = config?.duration || 9000;
  const role = config?.role || "status";
  const { smsList, close } = useSmsNotificationsStore((store) => ({
    smsList: store.smsList,
    close: store.close,
  }));
  const isShown = smsList.has(uniqueId);
  useEffect(() => {
    if (!duration || !isShown) {
      return;
    }
    const timeoutId = setTimeout(() => {
      close(uniqueId);
    }, duration);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [uniqueId, isShown, duration, close]);
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          key={uniqueId}
          layout
          initial={{ opacity: 0, x: 100, scale: 0.3 }}
          animate={{ opacity: 1, x: 0, scale: 1 }}
          exit={{ opacity: 0, x: 100, scale: 0.5 }}
          className="w-[350px] mb-1 flex items-start space-x-3 glass p-3 shadow-lg rounded-[10px] text-base z-50"
          role={role}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FakeSmsNotificationsPortal;
